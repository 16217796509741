import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Solutions.module.scss"

const Solutions = ({ strapiData }) => {
  return (
    <div className={`${styles.Challenges} `}>
      <Container className={styles.solutionsCon}>
        <div className={styles.hire}>
          <h2
            className={`${styles.hireHeading} `}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <div
            className={styles.subTitle2}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle2,
            }}
          />

          <Row className="gap-30">
            {strapiData?.cards &&
              strapiData?.cards?.map((item, i) => (
                <Col lg={4} className="d-flex gap-30">
                  <div className={styles.cards}>
                    <span>0{i + 1}</span>
                    <img
                      src={
                        item?.image1 && item?.image1[0]?.localFile?.publicURL
                      }
                      alt={(item?.image1 && item?.image1[0]?.alternativeText) || "image"}
                      decoding="async"
                      loading="lazy"
                      style={{ height: "60px", width: "60px" }}
                    />
                    <img
                      className={styles.tick}
                      src="https://invozone-backend.s3.us-east-1.amazonaws.com/Vector_581_38e6beaae9.png"
                      alt={item?.title}
                      decoding="async"
                      loading="lazy"
                      style={{
                        height: "15px",
                        width: "179px",
                        marginLeft: "15px",
                      }}
                    />
                    <div className={styles.text}>
                      <p>{item?.title}</p>
                    </div>
                  </div>
                </Col>
              ))}
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default Solutions
