import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Advantage.module.scss"

const Advantage = ({ strapiData }) => {
  return (
    <div className={`${styles.Challenges} `}>
      <Container>
        <div className={styles.hire}>
          <Row className="gap-30 align-items-center">
            <Col
              lg={{ span: 8, order: 1 }}
              md={{ span: 12, order: 1 }}
              xs={{ span: 12, order: 1 }}
            >
              <h2
                className={`${styles.hireHeading} `}
                dangerouslySetInnerHTML={{ __html: strapiData?.title }}
              />
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle,
                }}
              />
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default Advantage
